import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { ContentContainer } from 'components/layout/layout.css';
import Box from 'components/box';
import Title from 'components/title';
import { graphql } from 'gatsby';
import IOHeadline from '../components/io-headline';

const Privacy = ({ data }) => (
  <Layout>
    <ContentContainer>
      <Box>
        <IOHeadline />
        <Title as="h2" size="large">
          {data.privacyJson.title}
        </Title>
      </Box>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: data.privacyJson.content.childMarkdownRemark.html,
          }}
        />
      </Box>
    </ContentContainer>
  </Layout>
);

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Privacy;

export const query = graphql`
  query PrivacyQuery {
    privacyJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
